import { DisciplineTrackingCommentStatus } from "@/interfaces";

export const DISCIPLINE_STATUSES = {
  NOT_USED: "Not Used",
  NOT_DISTRIBUTED: "Not Distributed",
  NOT_STARTED: "New",
  WIP: "In Progress",
  AUTHORISED: "Authorised"
};

export const DISCIPLINE_MESSAGE = {
  SAVE_SUCCESSFUL: "The request has been saved",
  SAVE_AUTH_SUCCESSFUL: "The Authorisation Comment has been saved"
};

export const AUTH_COMMENT_BUTTON_LABELS = {
  SAVE: "APPLY",
  CANCEL: "CANCEL",
  RESTORE: "RESTORE"
};

export const AUTH_COMMENT_CONTENT_REGEX = new RegExp(
  "({{)([0-9A-Z_]+((_[A-F0-9]{8}-[A-F0-9]{4}-[A-F0-9]{4}-[A-F0-9]{4}-[A-F0-9]{12})?)+(_({)([0-9])(}))?)(}})",
  "g"
);

export const AUTH_COMMENT_ARRAY_REGEX = new RegExp("\\[(.*?)\\]", "g");

export const NVCP_TAG = {
  OVER_ALLOCATION: "Over Allocation"
};

export const TRACKING_COMMENT = {
  TITLE: "Tracking Comments",
  EMPTY_TITLE: "No tracking comments",
  EMPTY_SUBTITLE: "Add the relevant Tracking Comments for long lead approvals or reviews",
  LABEL_SHOW_HISTORICAL: "Show historical tracking"
};

export const DISCIPLINE_TRACKING_COMMENT_STATUS = {
  [DisciplineTrackingCommentStatus.InProgress]: "In progress",
  [DisciplineTrackingCommentStatus.Completed]: "Completed"
};

export const ClearingAllocationTable = {
  ADD_NVCP_TITLE: "Add Clearing Mechanism",
  UPDATE_NVCP_TITLE: "Update Clearing Mechanism",
  ADD_MS_TITLE: "Add Ministerial Statement",
  EDIT_MS_TITLE: "Edit Ministerial Statement"
};
