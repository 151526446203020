import { ChangeEvent, FocusEvent, useEffect, useState } from "react";
import { InputAdornment, SxProps, TextField, TextFieldProps, Typography } from "@mui/material";

export interface NumericTextFieldProps {
  maxLength?: number;
  unit?: string;
  defaultText?: string;
  isReadOnly?: boolean;
  onBlurSubmit?: (processedValue: string) => void;
  dataTestId?: string;
}

const disabledTypoStyles: SxProps = {
  color: "rgba(0, 0, 0, 0.38)"
};

export function NumericTextField({
  maxLength,
  unit,
  defaultText,
  isReadOnly,
  onBlurSubmit,
  onChange,
  size,
  dataTestId,
  ...props
}: NumericTextFieldProps & TextFieldProps) {
  const [textFieldValue, setTextFieldValue] = useState(defaultText);

  useEffect(() => {
    setTextFieldValue(defaultText);
  }, [defaultText]);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    if (value === "") {
      setTextFieldValue("");
    } else {
      const numericValue = value.replace(/[^0-9.]/g, "").replace(/e/gi, "");
      let truncatedValue = numericValue.slice(0, maxLength);
      if (isNaN(parseFloat(truncatedValue))) {
        truncatedValue = "0.";
      }
      setTextFieldValue(truncatedValue);
      // Have to set the updated value back to event so that downstream onChange(event) will get the reflected value.
      event.target.value = truncatedValue;
    }
    onChange && onChange(event);
  };

  const handleBlur = (event: FocusEvent<HTMLInputElement>) => {
    const { value } = event.target;
    if (value === "") return;
    const floatValue = parseFloat(value);
    const processedValue = floatValue === 0 ? "0.0" : floatValue.toString();
    setTextFieldValue(processedValue);
    onBlurSubmit?.(processedValue);
  };

  return (
    <TextField
      value={textFieldValue}
      InputProps={{
        readOnly: isReadOnly,
        endAdornment: (
          <InputAdornment position="end">
            <Typography sx={{ ...(isReadOnly ? disabledTypoStyles : {}) }}>{unit}</Typography>
          </InputAdornment>
        ),
        sx: {
          "& .MuiInputBase-input": {
            textAlign: "right"
          }
        },
        inputProps: {
          "data-testid": `${dataTestId ?? "numericField"}`
        }
      }}
      size={size}
      InputLabelProps={{
        shrink: true
      }}
      onBlur={handleBlur}
      onChange={handleChange}
      {...props}
      disabled={isReadOnly}
    />
  );
}
