import { useState, useMemo, useCallback } from "react";
import { MoreVert, Add, Check } from "@mui/icons-material";
import { MenuItem, Divider, Box, Stack, Typography, Chip, IconButton, Menu, Collapse, Button } from "@mui/material";
import dayjs from "dayjs";

import { DisciplineTrackingCommentStatus, DisciplineTrackingComment, InternalDependency } from "@/interfaces";
import { useDisciplineTabContext, useAuthorization } from "@/context";
import { useUpdateDisciplineTrackingComment } from "@/hooks";
import { ExpandToggle } from "@/components/shared";
import { DateInputField } from "@/components/fields";
import { AddTrackingCommentNote } from "./AddTrackingCommentNote";
import { SelectTrackingCommentDependency } from "./SelectTrackingCommentDependency";
import { TrackingCommentNotes } from "./TrackingCommentNotes";
import { DISCIPLINE_TRACKING_COMMENT_STATUS } from "@/constants";

interface TrackingCommentProps {
  disciplineTrackingComment: DisciplineTrackingComment;
  updateTrackingComment: (updatedComment: DisciplineTrackingComment, isDirty?: boolean) => void;
  deleteTrackingComment: (id: string) => void;
  hasNoTrackingCommentPermission: boolean;
}

export function TrackingComment({
  disciplineTrackingComment,
  updateTrackingComment,
  deleteTrackingComment,
  hasNoTrackingCommentPermission
}: TrackingCommentProps) {
  const {
    id,
    comment,
    status,
    expectedAuthorisationDate,
    trackingCommentId,
    notes,
    isInternal,
    isLegacy,
    isHistorical,
    disciplineTrackingCommentDependencies
  } = disciplineTrackingComment;

  const { isTabDirty, trackingCommentErrors, updateTrackingCommentErrors } = useDisciplineTabContext();
  const [expanded, setExpanded] = useState<boolean>(true);
  const [menuAnchor, setMenuAnchor] = useState<HTMLElement | null>(null);
  const menuIsOpen = Boolean(menuAnchor);
  const [openNote, setOpenNote] = useState<boolean>(false);
  const { name, userId } = useAuthorization();
  const { mutate } = useUpdateDisciplineTrackingComment((updatedDisciplineTrackingComment) => {
    updateTrackingComment(updatedDisciplineTrackingComment, false);
  });

  const isHistoricalTrackingComment = useMemo(() => isHistorical || isLegacy, [isHistorical, isLegacy]);

  const handleCloseMenu = () => {
    setMenuAnchor(null);
  };

  const addNoteOnClickHandler = (newNote: string) => {
    if (newNote === "") return;

    const updatedNotes = [
      {
        id: "",
        note: newNote,
        author: name ?? "",
        created: new Date(),
        authorId: userId ?? ""
      },
      ...notes
    ];

    updateTrackingComment({
      ...disciplineTrackingComment,
      notes: updatedNotes
    });

    setExpanded(true);
  };

  const completeOnClickHandler = useCallback(() => {
    mutate({
      ...disciplineTrackingComment,
      isComplete: true,
      status: DisciplineTrackingCommentStatus.Completed
    });
  }, [mutate, disciplineTrackingComment]);

  const inProgressOnClickHandler = useCallback(() => {
    mutate({
      ...disciplineTrackingComment,
      isComplete: false,
      status: DisciplineTrackingCommentStatus.InProgress
    });
  }, [mutate, disciplineTrackingComment]);

  const trackingCommentMenuItems = useMemo(
    () => [
      {
        key: "in-progress",
        label: "In Progress",
        onClick: () => {
          inProgressOnClickHandler();
          setMenuAnchor(null);
        },
        disabled: isTabDirty,
        display: status === DisciplineTrackingCommentStatus.Completed,
        hasDivider: false
      },
      {
        key: "add-note",
        label: "Add Note",
        onClick: () => {
          setOpenNote(true);
          setMenuAnchor(null);
        },
        disabled: false,
        display: status !== DisciplineTrackingCommentStatus.Completed && !isLegacy,
        hasDivider: false
      },
      {
        key: "complete",
        label: "Complete",
        onClick: () => {
          completeOnClickHandler();
          setMenuAnchor(null);
        },
        disabled: isTabDirty,
        display: status !== DisciplineTrackingCommentStatus.Completed || isLegacy,
        hasDivider: false
      },
      {
        key: "remove-tracking-comment",
        label: "Remove Tracking Comment",
        onClick: () => {
          handleCloseMenu();
          deleteTrackingComment(disciplineTrackingComment.id);
        },
        disabled: isTabDirty,
        display: status !== DisciplineTrackingCommentStatus.Completed && !isLegacy,
        hasDivider: true
      }
    ],
    [
      status,
      isLegacy,
      disciplineTrackingComment.id,
      isTabDirty,
      inProgressOnClickHandler,
      completeOnClickHandler,
      deleteTrackingComment
    ]
  );

  const menuItems = useMemo(() => {
    return trackingCommentMenuItems.map(
      (item) =>
        item.display && (
          <>
            {item.hasDivider && <Divider key="divider" />}
            <MenuItem {...item} data-testId={`menu-item-${item.key}-${disciplineTrackingComment.id}`}>
              {item.label}
            </MenuItem>
          </>
        )
    );
  }, [disciplineTrackingComment.id, trackingCommentMenuItems]);

  const handleDependencyChange = (disciplineTrackingCommentDependencies: InternalDependency[]) => {
    const updatedDependencies = disciplineTrackingCommentDependencies.map((dependency) => ({
      internalDependencyId: dependency.internalDependencyId,
      internalDependencyType: dependency.internalDependencyType,
      disciplineTrackingCommentId: disciplineTrackingComment.id
    }));

    updateTrackingCommentErrors((old) => ({
      ...old,
      [trackingCommentId]: {
        ...old[trackingCommentId],
        disciplineTrackingCommentDependencies: undefined
      }
    }));

    updateTrackingComment({
      ...disciplineTrackingComment,
      disciplineTrackingCommentDependencies: updatedDependencies
    });
  };

  return (
    <Box>
      <Stack direction="row" alignItems="center" justifyContent="space-between" height="2.5rem" spacing={2}>
        <Stack direction="row" spacing={1} alignItems="center">
          <ExpandToggle expanded={expanded} toggleExpansion={() => setExpanded((old) => !old)} />
          <Typography>{comment}</Typography>
          {isInternal && (
            <SelectTrackingCommentDependency
              selectedDependencies={disciplineTrackingCommentDependencies}
              handleDependencyChange={handleDependencyChange}
              readOnly={
                status === DisciplineTrackingCommentStatus.Completed || isHistorical || hasNoTrackingCommentPermission
              }
              error={trackingCommentErrors[trackingCommentId]?.disciplineTrackingCommentDependencies}
            />
          )}
        </Stack>
        <Stack direction="row" spacing={2} alignItems="center">
          <Chip
            label={DISCIPLINE_TRACKING_COMMENT_STATUS[status]}
            color={status === DisciplineTrackingCommentStatus.InProgress ? "warning" : "success"}
            style={{ marginRight: "10px" }}
            size="small"
            data-testid={`chip-tracking-comment-status-${id}`}
          />
          <DateInputField
            readOnly={
              hasNoTrackingCommentPermission ||
              isHistoricalTrackingComment ||
              status === DisciplineTrackingCommentStatus.Completed
            }
            label="Expected By"
            format="MMMM YYYY"
            views={["month", "year"]}
            minDate={dayjs()}
            value={expectedAuthorisationDate ? dayjs(expectedAuthorisationDate) : null}
            onChange={(value) => {
              updateTrackingCommentErrors((old) => ({
                ...old,
                [trackingCommentId]: {
                  ...old[trackingCommentId],
                  expectedAuthorisationDate: undefined
                }
              }));

              updateTrackingComment({
                ...disciplineTrackingComment,
                expectedAuthorisationDate: !value || !value.isValid() ? undefined : value.endOf("month").toDate()
              });
            }}
            sx={{ marginRight: "5px", p: 0 }}
            slotProps={{
              textField: {
                size: "small",
                inputProps: { "data-testid": `datepicker-expected-by-tracking-comment-${id}` }
              }
            }}
            error={trackingCommentErrors[trackingCommentId]?.expectedAuthorisationDate}
          />
          <IconButton
            onClick={(event) => setMenuAnchor(event.currentTarget)}
            aria-controls={menuIsOpen ? "account-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={menuIsOpen ? "true" : undefined}
            disabled={hasNoTrackingCommentPermission || isHistorical}
            aria-disabled={hasNoTrackingCommentPermission || isHistorical}
            data-testId={`button-kebab-tracking-comment-${id}`}
          >
            <MoreVert />
          </IconButton>
          <Menu
            id="basic-menu"
            anchorEl={menuAnchor}
            open={menuIsOpen}
            onClose={handleCloseMenu}
            MenuListProps={{
              "aria-labelledby": "basic-button"
            }}
            aria-disabled={hasNoTrackingCommentPermission || isHistorical}
            data-testId={`menu-kebab-tracking-comment-${trackingCommentId}`}
          >
            {menuItems}
          </Menu>
        </Stack>
      </Stack>
      <Collapse in={expanded}>
        <Box ml="3.5rem">
          {status === DisciplineTrackingCommentStatus.InProgress && (
            <Stack
              direction="row"
              alignItems="center"
              height="2.5rem"
              spacing="1rem"
              data-testId={`buttons-group-tracking-comment-${id}`}
            >
              <Button
                variant="text"
                size="small"
                startIcon={<Add />}
                onClick={() => {
                  setOpenNote(true);
                }}
                disabled={hasNoTrackingCommentPermission || isHistoricalTrackingComment}
                sx={{ p: 0 }}
                data-testId={`button-add-note-${id}`}
              >
                Add Note
              </Button>
              <Button
                variant="text"
                size="small"
                startIcon={<Check />}
                disabled={hasNoTrackingCommentPermission || isHistorical || isTabDirty}
                onClick={completeOnClickHandler}
                sx={{ p: 0 }}
                data-testId={`button-complete-${id}`}
              >
                Complete
              </Button>
            </Stack>
          )}

          <TrackingCommentNotes
            trackingComment={disciplineTrackingComment}
            notes={notes}
            updateTrackingCommentNotes={(notes) => {
              updateTrackingComment({
                ...disciplineTrackingComment,
                notes
              });
            }}
          />
          <AddTrackingCommentNote
            open={openNote}
            setOpen={setOpenNote}
            disciplineTrackingComment={disciplineTrackingComment}
            addTrackingCommentNote={addNoteOnClickHandler}
          />
        </Box>
      </Collapse>
    </Box>
  );
}
