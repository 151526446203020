import React, { useCallback, useEffect, useMemo, useState } from "react";
import { SelectChangeEvent } from "@mui/material";
import {
  DataGridPro,
  GridCellEditStopParams,
  GridCellEditStopReasons,
  GridCellModes,
  GridCellParams,
  GridColDef,
  GridPreProcessEditCellProps,
  GridRenderEditCellParams,
  GridRowId,
  GridValueFormatterParams,
  GridValueOptionsParams,
  useGridApiRef,
  MuiEvent,
  GridRenderCellParams
} from "@mui/x-data-grid-pro";
import { GridCellModesModel } from "@mui/x-data-grid/models/api/gridEditingApi";
import { useFormContext } from "react-hook-form";

import { AR_WC_ERRORS, DEFAULT_AREA_UNIT, mandatorySelectionErrorMessage } from "@/constants";
import {
  ARFormSection,
  ARFormValues,
  ApprovalRequestWorkCategoryItem,
  IOption,
  WorkSubCategoryItem
} from "@/interfaces";
import { IOptionSorter } from "@/utils";
import { useARContext } from "@/context";
import { ARDivider } from "@/components";
import { withARAwareReadOnly } from "@/components/hocs";
import { ARWorkCategoryToolbar } from "./ARWorkCategoryToolbar";
import { InlineActionInput, InlineNumericInput, InlineSingleSelect } from "./InlineDataGridInputs";

interface ARWorkCategoryProps {
  readOnly?: boolean;
  resetError?: () => void;
}

export function ARWorkCategory({ readOnly, resetError }: ARWorkCategoryProps) {
  const apiRef = useGridApiRef();

  const { approvalRequestId, approvalRequestType, workCategories, workSubCategories, fetchedARWorkCategoryList } =
    useARContext();

  const { setValue } = useFormContext<ARFormValues>();

  const [arWorkCategoryList, setARWorkCategoryList] = useState<ApprovalRequestWorkCategoryItem[]>(() =>
    fetchedARWorkCategoryList.map((workCategory) => {
      return {
        id: workCategory.id,
        workCategoryId: workCategory.workCategoryId,
        workSubCategoryId: workCategory.workSubCategoryId,
        answer: workCategory.answer,
        approvalRequestId: workCategory.approvalRequestId,
        displayOrder: workCategory.displayOrder,
        isMandatory: workCategory.isMandatory
      };
    })
  );

  const [cellModesModel, setCellModesModel] = useState<GridCellModesModel>({});
  const [isAbleToAddMoreRow, setIsAbleToAddMoreRow] = useState(false);

  // Single click to edit
  // reference from https://codesandbox.io/s/ypgvzd?file=/demo.tsx
  const handleCellClick = useCallback((params: GridCellParams, event: React.MouseEvent) => {
    if (!params.isEditable) {
      return;
    }

    // Ignore portal
    if (!event.currentTarget.contains(event.target as Element)) {
      return;
    }

    setCellModesModel((prevModel) => {
      return {
        // Revert the mode of the other cells from other rows
        ...Object.keys(prevModel).reduce(
          (acc, id) => ({
            ...acc,
            [id]: Object.keys(prevModel[id]).reduce(
              (acc2, field) => ({
                ...acc2,
                [field]: { mode: GridCellModes.View }
              }),
              {}
            )
          }),
          {}
        ),
        [params.id]: {
          // Revert the mode of other cells in the same row
          ...Object.keys(prevModel[params.id] || {}).reduce(
            (acc, field) => ({ ...acc, [field]: { mode: GridCellModes.View } }),
            {}
          ),
          [params.field]: { mode: GridCellModes.Edit }
        }
      };
    });
  }, []);

  const handleCellModesModelChange = useCallback((newModel: GridCellModesModel) => {
    setCellModesModel(newModel);
  }, []);

  // filter work sub-category based on work category id
  const handleFilterWorkSubCategoryList = useCallback(
    (selectedWorkCategoryId: string) =>
      workSubCategories.filter(
        (workSubCategory) => workSubCategory.workCategoryId === selectedWorkCategoryId && !workSubCategory.isMandatory
      ),
    [workSubCategories]
  );

  // filter work sub-category based on inputted result from data grid
  const handleFilterDuplicatedWorkSubCategoryList = useCallback(
    (filteredWorkSubCategoryList: WorkSubCategoryItem[]) =>
      filteredWorkSubCategoryList.filter(
        (workSubCategory) =>
          arWorkCategoryList.find(
            (savedOutcome) => workSubCategory.id === savedOutcome.workSubCategoryId && !workSubCategory.isMandatory
          ) === undefined
      ),
    [arWorkCategoryList]
  );

  // filter work category based on inputted result from data grid and
  // the remain available options from  work sub-category
  const handleFilterDuplicatedWorkCategoryList = useCallback(
    () =>
      workCategories.filter((workCategory) => {
        // Get the list of subcategory that belong to the work category
        let filteredSubWorkCategoryList = handleFilterWorkSubCategoryList(workCategory.id ?? "");
        filteredSubWorkCategoryList = handleFilterDuplicatedWorkSubCategoryList(filteredSubWorkCategoryList);

        // if work sub-category still have item
        return filteredSubWorkCategoryList.length !== 0;
      }),
    [handleFilterDuplicatedWorkSubCategoryList, handleFilterWorkSubCategoryList, workCategories]
  );

  const handleDeleteRowClick = useCallback(
    (id: GridRowId) => {
      // https://github.com/mui/mui-x/issues/2714
      setTimeout(() => {
        setARWorkCategoryList((prevRows) => {
          const newList = prevRows.filter((row) => row.displayOrder !== id);
          setValue("approvalRequestWorkCategories", newList, {
            shouldDirty: true
          });
          return newList;
        });
      });
    },
    [setARWorkCategoryList, setValue]
  );

  const handleUpdateRow = useCallback(
    (updatedRow: ApprovalRequestWorkCategoryItem) => {
      // Find the index of the row that was edited
      const rowIndex = arWorkCategoryList.findIndex(
        (workCategory) => workCategory.displayOrder === updatedRow.displayOrder
      );
      // Create a new list
      const newList = [...arWorkCategoryList];

      // Replace the old row with the updated row
      newList[rowIndex] = updatedRow;
      setARWorkCategoryList(newList);
      setValue("approvalRequestWorkCategories", newList, {
        shouldDirty: true
      });

      // Return the updated row to update the internal state of the DataGrid
      return updatedRow;
    },
    [arWorkCategoryList, setARWorkCategoryList, setValue]
  );

  const handleSelectValidation = (value: string | undefined, fieldName: string): string | null => {
    const isError = !value;
    return isError ? mandatorySelectionErrorMessage(fieldName) : null;
  };

  const handleAreaValidation = (value: number): string | null => {
    const isError = isNaN(value) || value <= 0;
    return isError ? AR_WC_ERRORS.INVALID_AREA_VALUE : null;
  };

  // This is to find the first available option to become the default value of the select component for work sub-category
  const handleWorkCategoryValueChange = useCallback(
    async (
      event: SelectChangeEvent,
      gridRenderEditCellParams: GridRenderEditCellParams<ApprovalRequestWorkCategoryItem>
    ) => {
      const value = event.target.value;
      // if the value is undefined and not guid
      if (value === undefined || value.length !== 36) return;
      const { id, field, row } = gridRenderEditCellParams;

      const filteredWorkSubCategoryList = handleFilterWorkSubCategoryList(value);
      const defaultWorkSubCategoryId =
        handleFilterDuplicatedWorkSubCategoryList(filteredWorkSubCategoryList).sort(IOptionSorter)[0]?.id;

      // This line will save the cell value and trigger the validation
      await apiRef.current.setEditCellValue({ id, field, value });

      const newRow = row;
      newRow.workCategoryId = value;
      newRow.workSubCategoryId = defaultWorkSubCategoryId;
      handleUpdateRow(newRow);
    },
    [apiRef, handleFilterDuplicatedWorkSubCategoryList, handleFilterWorkSubCategoryList, handleUpdateRow]
  );

  const handleWorkCategoryOnClose = useCallback(
    async (gridRenderEditCellParams: GridRenderEditCellParams<ApprovalRequestWorkCategoryItem>) => {
      const { id } = gridRenderEditCellParams;

      if (apiRef.current.getCellMode(id, "workSubCategoryId") === "view")
        apiRef.current.startCellEditMode({ id, field: "workSubCategoryId" });
    },
    [apiRef]
  );

  const handleWorkSubCategoryValueChange = useCallback(
    async (
      event: SelectChangeEvent,
      gridRenderEditCellParams: GridRenderEditCellParams<ApprovalRequestWorkCategoryItem>
    ) => {
      const value = event.target.value;
      // if the value is undefined and not guid
      if (value === undefined || value.length !== 36) return;
      const { id, row, field } = gridRenderEditCellParams;

      // This line will save the cell value and trigger the validation
      await apiRef.current.setEditCellValue({ id, field, value });

      const newRow = row;
      newRow.workSubCategoryId = value;
      handleUpdateRow(newRow);
    },
    [apiRef, handleUpdateRow]
  );

  const handleFocusOnArea = useCallback(
    (id: GridRowId, row: ApprovalRequestWorkCategoryItem) => {
      if (apiRef.current.getCellMode(id, "answer") === "view") {
        const deleteValue = row.answer === 0 ? true : false;
        apiRef.current.startCellEditMode({ id, field: "answer", deleteValue });
      }
    },
    [apiRef]
  );

  const handleWorkSubCategoryOnClose = useCallback(
    async (gridRenderEditCellParams: GridRenderEditCellParams<ApprovalRequestWorkCategoryItem>) => {
      const { id, row } = gridRenderEditCellParams;
      handleFocusOnArea(id, row);
    },
    [handleFocusOnArea]
  );

  const dataColumns = useMemo<GridColDef[]>(
    () => [
      {
        field: "displayOrder",
        type: "number"
      },
      {
        field: "workCategoryId",
        headerName: "Work Category",
        flex: 1,
        editable: !readOnly,
        resizable: true,
        sortable: false,
        hideable: false,
        type: "singleSelect",
        renderEditCell: (params: GridRenderEditCellParams<ApprovalRequestWorkCategoryItem>) => (
          <InlineSingleSelect
            gridRenderEditCellParams={params}
            disabled={params.row.isMandatory}
            initialOpen={!params.row.isMandatory}
            onChange={(event: SelectChangeEvent) => handleWorkCategoryValueChange(event, params)}
            onClose={() => handleWorkCategoryOnClose(params)}
            readOnly={readOnly}
            data-testid={`select-workcategory-$${params.api.getRowIndexRelativeToVisibleRows(params.id) + 1}`}
          />
        ),
        preProcessEditCellProps: (params: GridPreProcessEditCellProps<string>) => {
          const error = handleSelectValidation(params.props.value, "Work Category");
          return { ...params.props, error };
        },
        valueOptions: ({ row }: GridValueOptionsParams<ApprovalRequestWorkCategoryItem>) => {
          if (!row) return [];

          // mutual exclusive select to remove duplication on select component based on the value inputted on another row
          let filteredWorkCategoryList = handleFilterDuplicatedWorkCategoryList();

          // if work category id has been input,
          // To append the selected option back to the list to prevent selected option swallow by the data grid.
          if (
            row.workCategoryId &&
            filteredWorkCategoryList.find((item) => item.id === row.workCategoryId) === undefined
          ) {
            const selectedOption = workCategories.find((item) => item.id === row.workCategoryId);
            filteredWorkCategoryList = [selectedOption!, ...filteredWorkCategoryList];
          }

          return filteredWorkCategoryList.sort(IOptionSorter);
        },
        getOptionValue: (value: IOption) => value.id,
        getOptionLabel: (value: IOption) => value.value
      },
      {
        field: "workSubCategoryId",
        headerName: "Work Sub-Category",
        flex: 1,
        editable: !readOnly,
        resizable: true,
        sortable: false,
        hideable: false,
        type: "singleSelect",
        renderEditCell: (params: GridRenderEditCellParams<ApprovalRequestWorkCategoryItem>) => (
          <InlineSingleSelect
            gridRenderEditCellParams={params}
            disabled={params.row.isMandatory}
            initialOpen={!params.row.isMandatory}
            onClose={() => handleWorkSubCategoryOnClose(params)}
            onChange={(event: SelectChangeEvent) => handleWorkSubCategoryValueChange(event, params)}
            readOnly={readOnly}
            data-testid={`select-subworkcategory-${params.api.getRowIndexRelativeToVisibleRows(params.id) + 1}`}
          />
        ),
        preProcessEditCellProps: (params: GridPreProcessEditCellProps<string>) => {
          const error = handleSelectValidation(params.props.value, "Work Sub-Category");
          return { ...params.props, error };
        },
        valueOptions: ({ row }: GridValueOptionsParams<ApprovalRequestWorkCategoryItem>) => {
          // The row is not available when filtering this column
          if (!row) return [];

          let filteredWorkSubCategoryList = handleFilterWorkSubCategoryList(row.workCategoryId!);

          // mutual exclusive select to remove duplication on select component based on the value inputted on another row
          filteredWorkSubCategoryList = handleFilterDuplicatedWorkSubCategoryList(filteredWorkSubCategoryList);

          // if work subcategory id has been inputted,
          // To append the selected option back to the list to prevent selected option swallow by the data grid.
          if (
            row.workSubCategoryId &&
            filteredWorkSubCategoryList.find((item) => item.id === row.workSubCategoryId) === undefined
          ) {
            const selectedOption = workSubCategories.find(
              (item) => item.id === row.workSubCategoryId && item.workCategoryId === row.workCategoryId
            );
            if (selectedOption) filteredWorkSubCategoryList = [selectedOption, ...filteredWorkSubCategoryList];
          }

          return filteredWorkSubCategoryList.sort(IOptionSorter);
        },
        getOptionValue: (value: WorkSubCategoryItem) => value.id,
        getOptionLabel: (value: WorkSubCategoryItem) => value.value
      },
      {
        field: "answer",
        headerName: "Area",
        flex: 0.5,
        editable: !readOnly,
        resizable: true,
        sortable: false,
        hideable: false,
        headerAlign: "right",
        align: "right",
        renderEditCell: (params) => (
          <InlineNumericInput
            {...params}
            data-testid={`answer-${params.api.getRowIndexRelativeToVisibleRows(params.id) + 1}`}
          />
        ),
        renderCell: (params: GridRenderCellParams<ApprovalRequestWorkCategoryItem, number>) => {
          const value = params.value ?? 0;
          const error = handleAreaValidation(value);

          return (
            <InlineNumericInput
              error={error}
              {...params}
              data-testid={`answer-${params.api.getRowIndexRelativeToVisibleRows(params.id) + 1}`}
            />
          );
        },
        preProcessEditCellProps: ({
          props,
          row
        }: GridPreProcessEditCellProps<number, ApprovalRequestWorkCategoryItem>) => {
          resetError?.();
          const answerValue = props.value ?? 0;
          handleUpdateRow({
            ...row,
            answer: answerValue
          });
          const error = handleAreaValidation(answerValue);

          return { ...props, error: error };
        },
        valueFormatter: (params: GridValueFormatterParams<number>) => {
          let measurementUnit = DEFAULT_AREA_UNIT;
          if (params.id !== undefined) {
            const selectedWorkSubCategoryId = params.api.getRow<ApprovalRequestWorkCategoryItem>(
              params.id
            )?.workSubCategoryId;
            if (selectedWorkSubCategoryId !== undefined) {
              measurementUnit =
                workSubCategories.filter(
                  (workSubCategory) => workSubCategory.id.toLowerCase() === selectedWorkSubCategoryId.toLowerCase()
                )[0]?.measurementUnitName ?? DEFAULT_AREA_UNIT;
            }
          }

          if (params.value === null || isNaN(params.value)) {
            return `0.0 ${measurementUnit}`;
          }
          return `${params.value.toString()} ${measurementUnit}`;
        }
      },
      {
        field: "actions",
        headerName: "",
        width: 80,
        align: "center",
        renderCell: (params: GridRenderCellParams<ApprovalRequestWorkCategoryItem>) => {
          if (readOnly) return null;
          return (
            <InlineActionInput
              gridRenderEditCellParams={params}
              onClick={() => {
                handleDeleteRowClick(params.id);
              }}
              data-testid={`button-delete-workcategory-${params.api.getRowIndexRelativeToVisibleRows(params.id) + 1}`}
            />
          );
        }
      }
    ],
    [
      handleDeleteRowClick,
      handleFilterDuplicatedWorkCategoryList,
      handleFilterDuplicatedWorkSubCategoryList,
      handleFilterWorkSubCategoryList,
      handleUpdateRow,
      handleWorkCategoryOnClose,
      handleWorkCategoryValueChange,
      handleWorkSubCategoryOnClose,
      handleWorkSubCategoryValueChange,
      readOnly,
      resetError,
      workCategories,
      workSubCategories
    ]
  );

  // This is to force work category validation on when user click away from work category select.
  const handleOnCellEditStop = async (
    params: GridCellEditStopParams<ApprovalRequestWorkCategoryItem>,
    event: MuiEvent
  ) => {
    // Prevent the escape and tab key to cancel the work category
    if (
      params.reason === GridCellEditStopReasons.escapeKeyDown ||
      params.reason === GridCellEditStopReasons.tabKeyDown
    ) {
      event.defaultMuiPrevented = true;
      return;
    }

    if (params.reason === GridCellEditStopReasons.cellFocusOut) {
      const { id, field, value, row } = params;
      if (params.field === "workCategoryId" || params.field === "workSubCategoryId") {
        // to force validation trigger
        await apiRef.current.setEditCellValue({ id, field, value });
      }

      // To handle user click away from the workSubCategoryId
      // select option and won't focus on answer field issue.
      if (params.field === "workSubCategoryId") {
        handleFocusOnArea(id, row);
      }
    }
  };

  const handleOnCellKeyDown = async (params: GridCellParams, event: MuiEvent) => {
    if (params.colDef.type === "singleSelect") {
      event.defaultMuiPrevented = true;
      return;
    }
  };

  useEffect(() => {
    if (approvalRequestType.id === "") return;
    setIsAbleToAddMoreRow(handleFilterDuplicatedWorkCategoryList().length !== 0);
  }, [approvalRequestType.id, handleFilterDuplicatedWorkCategoryList]);

  return (
    <>
      <ARDivider />
      <DataGridPro
        sx={{ border: 0 }}
        density="compact"
        autoHeight
        apiRef={apiRef}
        checkboxSelection={false}
        disableColumnFilter
        disableColumnReorder
        disableColumnMenu
        disableRowSelectionOnClick
        columns={dataColumns}
        onCellEditStop={handleOnCellEditStop}
        onCellClick={handleCellClick}
        cellModesModel={cellModesModel}
        onCellModesModelChange={handleCellModesModelChange}
        onCellKeyDown={handleOnCellKeyDown}
        getRowId={(row: ApprovalRequestWorkCategoryItem) => row.displayOrder}
        rows={arWorkCategoryList}
        slots={{ toolbar: ARWorkCategoryToolbar }}
        slotProps={{
          toolbar: {
            approvalRequestId,
            isAbleToAddMoreRow,
            arWorkCategoryList,
            setARWorkCategoryList,
            readOnly
          }
        }}
        getCellClassName={() => (readOnly ? "Mui-readOnly" : "")}
        initialState={{
          sorting: {
            sortModel: [{ field: "displayOrder", sort: "desc" }]
          },
          columns: {
            columnVisibilityModel: {
              // Hide columns displayOrder, the other columns will remain visible
              displayOrder: false
            }
          }
        }}
        processRowUpdate={(updatedRow) => handleUpdateRow(updatedRow)}
        data-testid="datagrid-work-category"
      />
    </>
  );
}

export const ARAwareReadOnlyWorkCategory = withARAwareReadOnly(ARFormSection.WorkCategory, ARWorkCategory);
