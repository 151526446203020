import { Controller } from "react-hook-form";
import { FormControl, FormHelperText } from "@mui/material";
import { ARFormSection } from "@/interfaces";
import { withARAwareReadOnly } from "@/components/hocs";
import { HIGHLIGHT_FIELD_CLASS_NAMES } from "@/constants";
import { NumericTextField } from "./NumericTextField";
import { AppFormNumericInputProps } from "./AppFormProps";

export const AppFormNumericInput = ({
  name,
  control,
  error,
  showError = true,
  size,
  maxLength,
  formHelperErrorClass,
  isHighlight,
  readOnly,
  type,
  "data-testid": dataTestId,
  ...props
}: AppFormNumericInputProps) => {
  return (
    <FormControl fullWidth size={size}>
      <Controller
        name={name}
        control={control}
        render={({ field: { onChange, value } }) => (
          <NumericTextField
            maxLength={maxLength}
            type={type}
            className={isHighlight ? HIGHLIGHT_FIELD_CLASS_NAMES.BACKGROUND : undefined}
            onChange={(event) => {
              type === "number" ? onChange?.((event.target as HTMLInputElement).valueAsNumber) : onChange(event);
            }}
            isReadOnly={readOnly}
            value={value}
            dataTestId={dataTestId}
            {...props}
          />
        )}
      />
      <FormHelperText error sx={{ marginBottom: 1 }} className={error ? formHelperErrorClass : undefined}>
        {error?.message && showError ? error.message : " "}
      </FormHelperText>
    </FormControl>
  );
};

export const ARAwareReadOnlyAppFormInput = withARAwareReadOnly(ARFormSection.BasicForm, AppFormNumericInput);
