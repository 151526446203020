import { Grow, Stack } from "@mui/material";
import { Dialog, DialogAction } from "@/components/dialogs";
import { useForm } from "react-hook-form";
import dayjs, { Dayjs } from "dayjs";
import { PropertyErrorMessage } from "@/interfaces";
import { AppFormDateInput, AppFormInput } from "@/components/fields";
import { useEffect, useMemo } from "react";
import { getSmallestDate, greaterThanOrEqualTo } from "@/utils";
import { BUTTON_LABELS } from "@/constants";

interface AuthorisePermitFormValues {
  permitExpiryDate: string | null;
  permitDateOverrideReason: string;
}

interface AuthorisePermitFormProps {
  open: boolean;
  dismissModal: () => void;
  disciplinePermitDateError: PropertyErrorMessage | undefined;
  authorisePermitOnClickHandler: (
    permitExpiryDate: string,
    permitDateOverrideReason: string,
    minInvalidDisciplinePermitDate?: string
  ) => void;
}

export function AuthorisePermitForm({
  open,
  dismissModal,
  disciplinePermitDateError,
  authorisePermitOnClickHandler
}: AuthorisePermitFormProps) {
  const invalidDisciplinePermitDates: { disciplineName: string; permitDate: Dayjs | undefined }[] = useMemo(() => {
    if (!disciplinePermitDateError || !disciplinePermitDateError.formattedMessagePlaceholderValues) return [];
    return Object.entries(disciplinePermitDateError.formattedMessagePlaceholderValues).map(
      ([disciplineName, permitDate]) => {
        return {
          disciplineName: disciplineName,
          permitDate: permitDate === null ? undefined : dayjs(permitDate as string)
        };
      }
    );
  }, [disciplinePermitDateError]);

  const minInvalidDisciplinePermitDate = useMemo(() => {
    const availableDates = invalidDisciplinePermitDates.reduce((acc: Dayjs[], curr) => {
      if (curr.permitDate !== undefined) {
        acc.push(curr.permitDate);
      }
      return acc;
    }, []);
    if (availableDates.length === 0) {
      return null;
    }
    return getSmallestDate(availableDates);
  }, [invalidDisciplinePermitDates]);

  const {
    control,
    watch,
    reset,
    formState: { errors }
  } = useForm<AuthorisePermitFormValues>({
    defaultValues: {
      permitExpiryDate: minInvalidDisciplinePermitDate?.toISOString(),
      permitDateOverrideReason: ""
    }
  });

  useEffect(() => {
    const resetValues = {
      permitExpiryDate: minInvalidDisciplinePermitDate?.toISOString(),
      permitDateOverrideReason: ""
    };
    reset(resetValues, {
      keepDirty: false,
      keepDirtyValues: false,
      keepIsValid: false
    });
  }, [reset, minInvalidDisciplinePermitDate]);

  const [permitExpiryDate, permitDateOverrideReason] = watch(["permitExpiryDate", "permitDateOverrideReason"]);

  const isDefaultExpiryDate = useMemo(() => {
    return (
      permitExpiryDate &&
      minInvalidDisciplinePermitDate &&
      permitExpiryDate === minInvalidDisciplinePermitDate?.toISOString()
    );
  }, [permitExpiryDate, minInvalidDisciplinePermitDate]);

  const isInvalidOverrideReason = useMemo(
    () => (permitDateOverrideReason.length === 0 || permitDateOverrideReason.trim() === "") && !isDefaultExpiryDate,
    [permitDateOverrideReason, isDefaultExpiryDate]
  );

  const authorisePermitFormActions: DialogAction[] = useMemo(
    () => [
      {
        label: BUTTON_LABELS.CANCEL,
        onClick: () => {
          dismissModal();
          reset();
        }
      },
      {
        label: BUTTON_LABELS.AUTHORISE,
        onClick: () => {
          if (
            errors.permitExpiryDate ||
            errors.permitDateOverrideReason ||
            !permitExpiryDate ||
            isInvalidOverrideReason
          )
            return;
          authorisePermitOnClickHandler(
            permitExpiryDate,
            permitDateOverrideReason.trim(),
            minInvalidDisciplinePermitDate?.toISOString()
          );
        },
        isFormSubmit: true,
        disabled:
          !permitExpiryDate || !greaterThanOrEqualTo(dayjs(permitExpiryDate!), dayjs()) || isInvalidOverrideReason
      }
    ],
    [
      permitExpiryDate,
      isInvalidOverrideReason,
      dismissModal,
      reset,
      errors.permitExpiryDate,
      errors.permitDateOverrideReason,
      authorisePermitOnClickHandler,
      permitDateOverrideReason
    ]
  );

  return (
    <Dialog
      fullWidth
      TransitionComponent={Grow}
      open={open}
      onClose={() => {
        reset();
      }}
      title="Authorise Permit?"
      actions={authorisePermitFormActions}
    >
      {disciplinePermitDateError?.errorMessage}
      <Stack sx={{ pl: "2rem" }}>
        <ul>
          {invalidDisciplinePermitDates &&
            invalidDisciplinePermitDates.map(({ disciplineName }) => {
              if (disciplineName === "") return;
              return <li id={disciplineName}>{disciplineName}</li>;
            })}
        </ul>
      </Stack>
      <Stack alignContent="center" sx={{ mt: "1rem" }}>
        <AppFormDateInput
          name={"permitExpiryDate"}
          control={control}
          label={"Expiry Date"}
          showError={true}
          minDate={dayjs()}
          error={errors.permitExpiryDate}
          data-testid="permit-expiry-date-date-input"
          required={true}
        />
        <AppFormInput
          control={control}
          label={"Reason for decision"}
          name={"permitDateOverrideReason"}
          error={errors.permitDateOverrideReason}
          showError={true}
          multiline={true}
          rows={4}
          maxLength={500}
          required={!isDefaultExpiryDate}
          data-testid="permit-date-override-reason-input"
        />
      </Stack>
    </Dialog>
  );
}
