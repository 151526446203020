import { useMemo, useState } from "react";
import { Button, colors, Grid, Stack } from "@mui/material";

import { AR_SHOW_SUBJECT_MATTER_EXPERTS_LOCATORS, EMPTY_GUID } from "@/constants";
import { useARContext } from "@/context";
import { ARDivider } from "@/components";
import { ARShowDate } from "./ARShowDate";
import { ARShowPeopleComponent } from "./ARShowPeopleComponent";
import { ARShowSubjectMatterExpertsComponent } from "./ARShowSubjectMatterExpertsComponent";
import { ARStatusTimeline } from "./ARStatusTimeline";

export function ARDetailsOverview() {
  const { approvalRequestId, approvalRequest, approvalRequestDisciplines } = useARContext();
  const [showMoreSection, setShowMoreSection] = useState(false);

  // Note that approver data only exists once the AR has been distributed however it is created at submission so filter out any unassigned data.
  const assignedDisciplines = useMemo(
    () => approvalRequestDisciplines.filter((x) => x.primaryApprover !== EMPTY_GUID),
    [approvalRequestDisciplines]
  );

  const hasApproverData = useMemo(() => assignedDisciplines.length > 0, [assignedDisciplines.length]);

  const toggleShowMoreSection = () => setShowMoreSection((currentValue) => !currentValue);

  return (
    <Grid item height="100%" px="1.5rem" py="1rem">
      <Grid container>
        <ARStatusTimeline approvalRequestId={approvalRequestId} />
      </Grid>
      <ARDivider />
      <Grid container>
        <Grid item>
          <ARShowDate permitExpiryDate={approvalRequest.permitExpiryDate} />
        </Grid>
      </Grid>
      <ARDivider />
      <Grid container>
        <Grid item>
          <ARShowPeopleComponent
            requestorName={approvalRequest?.requestorName ?? ""}
            ownerName={approvalRequest?.ownerName ?? ""}
            coordinatorName={approvalRequest?.coordinatorName ?? ""}
            endorserName={approvalRequest?.endorserName ?? ""}
          />
        </Grid>
      </Grid>

      <Stack margin="0.75rem 0">
        <Button
          onClick={toggleShowMoreSection}
          disabled={!hasApproverData}
          sx={{ color: hasApproverData ? colors.blue : colors.grey }}
          data-testid={AR_SHOW_SUBJECT_MATTER_EXPERTS_LOCATORS.TOGGLE_MORE_BUTTON}
        >
          {showMoreSection ? "Less" : "More"}
        </Button>
      </Stack>

      {showMoreSection ? (
        <ARShowSubjectMatterExpertsComponent approvalRequestDisciplines={assignedDisciplines} />
      ) : null}
    </Grid>
  );
}
