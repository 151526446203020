import { Dispatch, SetStateAction, useMemo } from "react";
import { BUTTON_LABELS, DEFAULT_AREA_UNIT } from "@/constants";
import { Box, Button, Grid, Typography } from "@mui/material";
import { GridToolbarContainer } from "@mui/x-data-grid-pro";
import { Dialog, DialogAction } from "@/components/dialogs";
import { ClearingAllocation, DisciplineAuthorisationCommentValue } from "@/interfaces";
import { useForm } from "react-hook-form";
import { AppFormInput, AppFormNumericInput } from "@/components/fields";
import { clearingAllocationSchema } from "@/validations";
import { useAuthorization } from "@/context";
import dayjs from "dayjs";
import { isEqual } from "lodash";

interface EPBCClearingAllocationTableToolbarProps {
  setModalDialogAction: Dispatch<SetStateAction<"Add" | "Edit" | "View" | undefined>>;
  modalDialogAction: "Add" | "Edit" | "View" | undefined;
  disabled: boolean;
  rowIsSelected: boolean;
  selectedRow: ClearingAllocation;
  setSelectedRow: Dispatch<SetStateAction<ClearingAllocation>>;
  disciplineAuthCommentValue: DisciplineAuthorisationCommentValue;
  clearingAllocations: ClearingAllocation[];
  onUpdate: (updatedValue: DisciplineAuthorisationCommentValue) => void;
  isViewMode?: boolean;
  refreshAvailableBalance: () => void;
}

export function EPBCClearingAllocationTableToolbar({
  setModalDialogAction,
  modalDialogAction,
  disabled,
  rowIsSelected,
  selectedRow,
  setSelectedRow,
  disciplineAuthCommentValue,
  clearingAllocations,
  onUpdate,
  isViewMode
}: EPBCClearingAllocationTableToolbarProps) {
  const { userId, username } = useAuthorization();

  const defaultValues: ClearingAllocation = {
    id: crypto.randomUUID(),
    referenceNo: "",
    clearingAllocation: null,
    noClearingAfterDate: undefined,
    expiryDate: undefined,
    restrictedClearingId: "",
    restrictedClearing: "",
    restrictedClearingAllocation: null,
    termsAndConditions: "",
    createdBy: userId,
    createdByName: username
  };

  const {
    control,
    watch,
    getValues,
    setValue,
    formState: { errors },
    reset
  } = useForm<ClearingAllocation>({ defaultValues });

  const modalDialogIsOpen = modalDialogAction !== undefined;
  const formValues = watch();
  const epbcReference = watch("referenceNo")?.trim();
  const restrictedClearingId = watch("restrictedClearingId")?.trim();

  const applyButtonEnabled = useMemo(() => {
    if (epbcReference === "") return false;

    if (modalDialogAction === undefined) {
      return;
    }

    if (formValues.restrictedClearingAllocation !== null && isNaN(formValues.restrictedClearingAllocation!))
      setValue("restrictedClearingAllocation", null);

    if (
      formValues.restrictedClearingId !== null &&
      formValues.restrictedClearingId!.length > 0 &&
      restrictedClearingId?.length === 0
    )
      return false;

    try {
      clearingAllocationSchema.validateSync(getValues(), { abortEarly: false });
    } catch (_error) {
      return false;
    }

    if (modalDialogAction === "Edit") {
      const valuesEqual = isEqual(selectedRow, formValues);

      if (valuesEqual) {
        return false;
      }
    }

    return true;
  }, [modalDialogAction, selectedRow, formValues]);

  const handleDialogOpen = (action: "Add" | "Edit" | "View") => {
    if (action === "Edit" || action === "View") {
      reset(selectedRow);
    } else {
      reset(defaultValues);
    }
    setModalDialogAction(action);
  };

  const handleDialogClose = () => {
    setModalDialogAction(undefined);
  };

  const handleAdd = () => {
    const addedFormValues = formValues;

    // While restricted clearing is a manual input field, we need to prevent submitting the restricted allocation where the clearing allocation ID can be removed but the hectare field input remained
    if (restrictedClearingId === "") addedFormValues.restrictedClearingAllocation = null;

    addedFormValues.created = dayjs().utc().toDate();

    const newClearingAllocation = [addedFormValues, ...clearingAllocations];

    onUpdate({
      ...disciplineAuthCommentValue,
      value: JSON.stringify(newClearingAllocation, (_k, v) => (v === undefined ? null : v))
    });

    handleDialogClose();
  };

  const handleEdit = () => {
    const editedFormValues = formValues;

    // While restricted clearing is a manual input field, we need to prevent submitting the restricted allocation where the clearing allocation ID can be removed but the hectare field input remained
    if (restrictedClearingId === "") editedFormValues.restrictedClearingAllocation = null;

    editedFormValues.lastModified = dayjs().utc().toDate();
    editedFormValues.lastModifiedBy = userId;
    editedFormValues.lastModifiedByName = username;

    const updatedClearingAllocation = clearingAllocations.map((row) =>
      row.id === editedFormValues.id ? editedFormValues : row
    );
    setSelectedRow(editedFormValues);
    onUpdate({
      ...disciplineAuthCommentValue,
      value: JSON.stringify(updatedClearingAllocation)
    });

    handleDialogClose();
  };

  const handleDelete = () => {
    const filteredClearingAllocations = clearingAllocations.filter((row) => row.id !== selectedRow.id);

    onUpdate({
      ...disciplineAuthCommentValue,
      value: JSON.stringify(filteredClearingAllocations)
    });
  };

  const getTitleName = () => {
    if (modalDialogAction === "Add") {
      return "Add Decision Notice";
    } else if (modalDialogAction === "View") {
      return `EPBC: ${epbcReference}`;
    } else {
      return "Edit Decision Notice";
    }
  };

  const actionButtonList: DialogAction[] = [
    {
      label: BUTTON_LABELS.CANCEL,
      onClick: handleDialogClose,
      disabled: false
    },
    {
      label: BUTTON_LABELS.APPLY,
      disabled: !applyButtonEnabled,
      onClick: modalDialogAction === "Add" ? handleAdd : handleEdit
    }
  ];

  return (
    <GridToolbarContainer sx={{ display: "flex", justifyContent: "space-between", marginBottom: "8px" }}>
      <Box>
        <Typography fontSize={"1.125rem"}>Decision Notices</Typography>
      </Box>
      <Box justifyContent={"flex-end"}>
        {isViewMode ? (
          <>
            <Button
              variant="text"
              color="secondary"
              data-testid="epbc-table-view-button"
              disabled={!rowIsSelected}
              onClick={() => handleDialogOpen("View")}
            >
              View
            </Button>
          </>
        ) : (
          <>
            <Button
              variant="text"
              color="secondary"
              data-testid="epbc-table-add-button"
              disabled={disabled}
              onClick={() => handleDialogOpen("Add")}
            >
              Add
            </Button>
            <Button
              variant="text"
              color="secondary"
              data-testid="epbc-table-edit-button"
              disabled={!rowIsSelected || disabled}
              onClick={() => handleDialogOpen("Edit")}
            >
              Edit
            </Button>
            <Button
              variant="text"
              color="secondary"
              data-testid="epbc-table-delete-button"
              disabled={!rowIsSelected || disabled}
              onClick={handleDelete}
            >
              Delete
            </Button>
          </>
        )}

        <Dialog
          fullWidth={true}
          maxWidth={"lg"}
          data-testid={"clearing-allocation-modal"}
          open={modalDialogIsOpen}
          onClose={handleDialogClose}
          title={getTitleName()}
          actions={modalDialogAction !== "View" ? actionButtonList : []}
          showCloseIcon={isViewMode}
        >
          <Grid container spacing={{ xs: 1, sm: 2 }} columns={12} sx={{ pt: 1 }}>
            <Grid item xs={6}>
              <AppFormInput
                name={"referenceNo"}
                control={control}
                label={"EPBC Reference"}
                error={errors.referenceNo}
                data-testid="input-epbc-reference"
                showError={false}
                type="text"
                readOnly={isViewMode}
              />
            </Grid>
            <Grid item xs={6}>
              <AppFormNumericInput
                control={control}
                label={"Clearing allocation (ha)"}
                name={"clearingAllocation"}
                disabled={!Boolean(epbcReference) && epbcReference?.length === 0}
                error={errors.clearingAllocation}
                data-testid="input-clearing-allocation"
                showError={false}
                type="number"
                readOnly={isViewMode}
              />
            </Grid>
            <Grid item xs={6}>
              <AppFormInput
                name={"restrictedClearingId"}
                control={control}
                label={"Restricted clearing"}
                error={errors.restrictedClearingId}
                data-testid="input-restricted-clearing-id"
                showError={false}
                type="text"
                readOnly={isViewMode}
              />
            </Grid>
            <Grid item xs={6}>
              <AppFormNumericInput
                control={control}
                label={"Restricted clearing allocation (ha)"}
                name={"restrictedClearingAllocation"}
                disabled={!Boolean(restrictedClearingId) && restrictedClearingId?.length === 0}
                error={errors.restrictedClearingAllocation}
                data-testid="input-restricted-clearing-allocation"
                showError={false}
                type="number"
                readOnly={isViewMode}
                unit={DEFAULT_AREA_UNIT}
              />
            </Grid>
            <Grid item xs={12}>
              <AppFormInput
                control={control}
                label={"Terms and Conditions"}
                name={"termsAndConditions"}
                error={errors.termsAndConditions}
                data-testid="input-terms-and-conditions"
                showError={false}
                multiline={true}
                rows={5}
                readOnly={isViewMode}
              />
            </Grid>
          </Grid>
        </Dialog>
      </Box>
    </GridToolbarContainer>
  );
}
